import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34')
];

export const server_loads = [0];

export const dictionary = {
		"/": [2],
		"/account-settings": [3],
		"/admin": [4],
		"/admin/users": [5],
		"/admin/users/[id]": [6],
		"/analyze/grassland-quality": [7],
		"/businesses": [8],
		"/businesses/profile/[[id]]": [10],
		"/businesses/[id]": [9],
		"/contact": [11],
		"/error-roles": [12],
		"/external-businesses/[id]": [13],
		"/find-vendors": [~14],
		"/haying-grazing": [15],
		"/haying-grazing/participant-requests": [20],
		"/haying-grazing/requests": [21],
		"/haying-grazing/[[id=integer]]/request": [16],
		"/haying-grazing/[id=integer]/approve": [17],
		"/haying-grazing/[id=integer]/certify": [18],
		"/haying-grazing/[id=integer]/forms": [19],
		"/login": [22],
		"/manage-content": [23],
		"/manage-content/detail/[[slug]]": [24],
		"/pages/[slug]": [25],
		"/plant-finder": [26],
		"/reset-password": [27],
		"/seed-inventory/[[id]]/upload": [29],
		"/seed-inventory/[[id]]": [28],
		"/seed-tracker": [30],
		"/seed-tracker/planting": [31],
		"/set-password/[token]": [32],
		"/tutorials": [33],
		"/vendor-browse": [34]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';